export const HOLDERS_CLUB_LINK = 'https://app.jpool.one/holders-club/rewards'
export const HOLDERS_CLUB_APY_URL = 'https://api.hc.jpool.one'

export const BOT_NAME = 'PumpjackBot'

export const COMMUNITY_LINK = 'https://t.me/pumpjackgame'

export const PUMPJACK_APY__URL = import.meta.env.VITE_PUMPJACK_API_URL

export const RELOAD_EXCHANGE_TIME = 600 // reload exchange every 10 minutes
